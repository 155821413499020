exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-chatbot-copy-js": () => import("./../../../src/pages/chatbot copy.js" /* webpackChunkName: "component---src-pages-chatbot-copy-js" */),
  "component---src-pages-chatbot-js": () => import("./../../../src/pages/chatbot.js" /* webpackChunkName: "component---src-pages-chatbot-js" */),
  "component---src-pages-eduboost-js": () => import("./../../../src/pages/eduboost.js" /* webpackChunkName: "component---src-pages-eduboost-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

